export const appRed = "#F54F64";
export const appBrown = "#5c4324";
export const appBlack = "#17202A";
export const appPurple = "#4A235A";

export const test = "#6444b4";

export const appMain = appPurple;

export const xMargin = { xs: "16px", sm: "24px", md: "24px" };

export const titleStyle = { fontWeight: "bold", fontSize: "32px" };
export const smallTitleStyle = { fontWeight: "bold", fontSize: "16px" };
export const weightTextStyle = { fontWeight: "bold", fontSize: "14px" };

export const TEXT_NOT_SELECTABLE = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  msTouchSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const HIGHLIGHT_REMOVED = {
  WebkitTapHighlightColor: "transparent",
};

export const NOT_SELECTABLE = {
  ...TEXT_NOT_SELECTABLE,
  ...HIGHLIGHT_REMOVED,
};

const themeFile = () => {
  return {
    palette: {
      primary: {
        main: `${appMain}`,
        light: `${appMain}`,
        dark: `${appMain}`,
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff6333",
        main: "#ff3d00",
        dark: "#b22a00",
        contrastText: "#fff",
      },
      error: {
        light: appRed,
        main: appRed,
        dark: appRed,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            logo: "p",
            navbar: "p",
            navbarSmall: "p",
            title: "h1",
            subtitle: "h2",
            tableText: "p",
            tableHeader: "h3",
            inputTitle: "p",
            buttonText: "p",
            settingsButtonText: "p",
            groupButtonText: "p",
            floatingButton: "p",
            error: "p",
            /* SETTINGS TEXTX */
            settHeader: "h2",
            settHeaderSmall: "h3",
            settText: "p",
            /* SMALL NAVIGATION */
            navHeader: "h2",
            navText: "p",
            /* FOOTER */
            footerText: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",
            ...(ownerState.variant === "logo" && {
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              lineHeight: "16px",
              color: "#fbfbfb",
              textAlign: "left",
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          //disableFocusRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 0,
          }),
        },
      },
    },
    typography: {
      useNextVariants: true,
    },
  };
};
export default themeFile;

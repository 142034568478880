import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { lazy } from "react";
import { smallTitleStyle, titleStyle } from "../../Design/theme";
import "./RoundedList.css";

/*import RodinnePravo from "./Services/RodinnePravo";
import ObcanskePravo from "./Services/ObcanskePravo";
import TrestniPravo from "./Services/TrestniPravo";
import ObchodniPravo from "./Services/ObchodniPravo";
import PracovniPravo from "./Services/PracovniPravo";
import SmenecnePravo from "./Services/SmenecnePravo";
import Insolvence from "./Services/Insolvence";*/

import { theme } from "../../../App";

import ContractImage from "../../Design/Files/ContractSigning.jpg";
import Loadable from "../../Router/Loaders/Loadable";

const RodinnePravo = Loadable(lazy(() => import("./Services/RodinnePravo")));
const ObcanskePravo = Loadable(lazy(() => import("./Services/ObcanskePravo")));
//const TrestniPravo = Loadable(lazy(() => import("./Services/TrestniPravo")));
const ObchodniPravo = Loadable(lazy(() => import("./Services/ObchodniPravo")));
const PracovniPravo = Loadable(lazy(() => import("./Services/PracovniPravo")));
const SmenecnePravo = Loadable(lazy(() => import("./Services/SmenecnePravo")));
const Insolvence = Loadable(lazy(() => import("./Services/Insolvence")));

export const services = [
  { name: "Rodinné právo", path: "/pravo/rodinne", element: <RodinnePravo /> },
  {
    name: "Občanské právo",
    path: "/pravo/obcanske",
    element: <ObcanskePravo />,
  },
  //{ name: "Trestní právo", path: "/pravo/trestni", element: <TrestniPravo /> },
  {
    name: "Korporátní právo",
    path: "/pravo/obchodni",
    element: <ObchodniPravo />,
  },
  {
    name: "Pracovní právo",
    path: "/pravo/pracovni",
    element: <PracovniPravo />,
  },
  {
    name: "Směnečné právo",
    path: "/pravo/smenecne",
    element: <SmenecnePravo />,
  },
  { name: "Insolvence", path: "/insolvence", element: <Insolvence /> },
];

const flexServices = [
  "Telefonické, osobní a mailové konzultace",
  "Návrhy řešení a postupů konkrétních právních sporů",
  "Provedení právních rozborů a vypracování stanovisek",
  "Sepis žalob a dalších podání k soudům a správním orgánům",
  "Zastupování v soudních, rozhodčích a správních řízeních",
  "Jednání s protistranou",
  "Zpracování veškeré smluvní dokumentace, včetně revize smluv",
  "Advokátní úschovy",
  "Konverze listin",
  "Ověřování podpisů",
];

export default function Services() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <div>
      <Container maxWidth="lg">
        {/*<Typography sx={{ ...titleStyle, my: "60px" }}>Služby</Typography>*/}
        <div style={{}}>
          <Typography
            variant="h1"
            sx={{ ...titleStyle, textAlign: "center", mt: "40px", mb: "50px" }}
          >
            Advokátní služby
          </Typography>
          <Box
            sx={{
              mt: "40px",
              mb: "60px",
              //display: "flex",
              //flexDirection: "row",
            }}
          >
            <Grid container columns={24}>
              <Grid item xs={24} sm={12}>
                <ol
                  className="rounded-list"
                  style={{
                    textAlign: "center",
                    maxWidth: "100%",
                  }}
                >
                  {services.map((data, index) => (
                    <Box
                      component="li"
                      sx={{
                        marginLeft: "10px",
                        minWidth: "290px",
                        maxWidth: mobile ? "100%" : "400px",
                      }}
                      key={"LiServicesList" + index}
                    >
                      <a href={data.path} style={{}}>
                        {data.name}
                      </a>
                    </Box>
                  ))}
                </ol>
              </Grid>
              {!mobile && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { sm: "flex-end", md: "center" },
                  }}
                >
                  <img
                    style={{
                      maxWidth: "220px",
                      maxHeight: "220px",
                      borderRadius: "10px",
                      marginTop: ".9em",
                    }}
                    src={ContractImage}
                    alt=""
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Typography
            sx={{ ...titleStyle, textAlign: "center", mt: "40px", mb: "60px" }}
          >
            Komplexní právní služby
          </Typography>
          <Typography
            sx={{ ...smallTitleStyle, textAlign: "left", maxWidth: "500px" }}
          >
            V rámci komplexních právních služeb nabízíme zejména následující
            způsoby právní pomoci:
          </Typography>
          <ul
            style={{
              textAlign: "left",
              paddingLeft: "15px",
              marginTop: "40px",
              marginBottom: "60px",
            }}
          >
            {flexServices.map((data, index) => (
              <li key={"LiFlexList" + index} style={{ paddingLeft: "8px" }}>
                {data}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );
}

import * as React from "react";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MobileDrawerMenu from "./MobileDrawer";
import { theme } from "../../../App";
import Logo from "../../Design/Files/Logo";
import { appMain, xMargin } from "../../Design/theme";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Motion } from "../Main/Motion";

export const navbarPaths = [
  { name: "Naše služby", path: "/sluzby" },
  { name: "Kontakt", path: "/kontakt" },
];

export default function Navbar({ references }) {
  const [state, setState] = React.useState(false);
  const [bgTransparent, setBgTransparent] = React.useState(false);
  const navigate = useNavigate();
  const sandwichMenu = useMediaQuery(theme.breakpoints.down("md"));

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window : undefined,
  });

  React.useEffect(() => {
    if (!state) {
      setTimeout(() => {
        setBgTransparent(false);
      }, 250);
    } else {
      setBgTransparent(true);
    }
  }, [state]);

  React.useEffect(() => {
    if (window && sandwichMenu && state) {
      window.onpopstate = (e) => {
        setState(false);
      };
    }
  });

  return (
    <header>
      <Box
        sx={{
          height: `fit-content`,
          width: "100%",
          bgcolor: elevated ? appMain : "#fbfbfb",
          opacity: 0.95,
          position: "fixed",
          top: 0,
          zIndex: 10000,
          boxShadow: elevated ? "0px 2px 5px 0px rgba(0,0,0,0.2)" : "none",
          py: "6px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            px: xMargin,
            //zIndex: zindexPublic.navbar,
            height: `fit-content`,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: `${50}px`,
              py: "8px",
              //px: VERTICAL_SPACE,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                mr: "auto",
              }}
            >
              <Box
                component={Link}
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  m: "10px",
                  ml: "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                  //...HIGHLIGHT_REMOVED,
                }}
                onClick={() => {
                  navigate("/");
                  setState(false);
                }}
                to="/"
              >
                <Logo
                  sx={{
                    maxHeight: "65px",
                    height: "65px",
                    width: "180px",
                    maxWidth: "180px",
                  }}
                  color={elevated ? "#fbfbfb" : appMain}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                ml: "auto",
              }}
            >
              {!sandwichMenu && (
                <>
                  {navbarPaths.map((nav, index) => (
                    <Box
                      key={"NavbarLink" + index}
                      component={Link}
                      sx={{
                        textDecoration: "none",
                        mx: "3px",
                        px: "10px",
                        py: "8px",
                        color: elevated ? "#fbfbfb" : appMain,
                      }}
                      href={nav.path}
                      to={nav.path}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                      >
                        {nav.name}
                      </Typography>
                    </Box>
                  ))}
                  <Motion>
                    <Box
                      sx={{
                        width: "36px",
                        height: "36px",
                        bgcolor: elevated ? "#fbfbfb" : appMain,
                        borderRadius: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        ml: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("mailto:kancelar@ak-hpk.cz", "_self");
                      }}
                    >
                      <EmailRoundedIcon
                        sx={{ color: elevated ? appMain : "#fbfbfb" }}
                      />
                    </Box>
                  </Motion>
                </>
              )}
            </Box>
            {sandwichMenu && (
              <Box sx={{ ml: "10px" }}>
                <MobileDrawerMenu
                  references={references}
                  state={state}
                  setState={setState}
                  elevated={elevated}
                />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </header>
  );
}

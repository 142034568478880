import { Box } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";

export function Motion(props) {
  const { scale, stiffness, damping, dontHover } = props;

  const clonedComp = React.cloneElement(props.children, {
    component: motion.div,
    whileHover: { scale: scale || (dontHover ? 1 : 1.08) },
    transition: {
      type: "spring",
      stiffness: stiffness || 150,
      damping: damping || 10,
    },
  });

  return <>{clonedComp}</>;
}

import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { appMain, smallTitleStyle, xMargin } from "../../Design/theme";
import { theme } from "../../../App";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";

export default function Footer() {
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "120px",
          bgcolor: appMain,
          boxShadow: "0px -1px 4px 0px rgba(187,187,187,1)",
          WebkitBoxShadow: "0px -1px 2px 0px rgba(187,187,187,1)",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            width: "100%",
            height: "100%",
            py: "20px",
            px: xMargin,
            display: "flex",
            flexDirection: "row",
            /*display: "flex",
            alignItems: "center",
            alignContent: "center",*/
          }}
        >
          <Box
            sx={{
              mr: "auto",
              width: "fit-content",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <Typography
              component="a"
              href="/"
              sx={{
                color: "white",
                fontSize: "17px",
                textDecoration: "none",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Ak-hpk.cz
            </Typography>
            <Box
              sx={{
                mt: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "13px",
                  textDecoration: "none",
                  fontWeight: "normal",
                  minWidth: "115px",
                }}
              >
                {mobile
                  ? "Advokátní kancelář"
                  : "Advokátní kancelář | Mgr. Marta Hubková | IČO: 49759329 | Adresa: Nerudova 320/6, 602 00 Brno"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              ml: "auto",
              width: "fit-content",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
              alignItems: "flex-end",
              alignContent: "flex-end",
            }}
          >
            <Typography
              component="a"
              href="/ochrana"
              sx={{
                color: "white",
                fontSize: "14px",
                textDecoration: "none",
                fontWeight: "bold",
                minWidth: "115px",
              }}
            >
              Zásady ochrany soukromí
            </Typography>
            <Box
              sx={{
                mt: "auto",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                alignContent: "center",
                cursor: "pointer",
                width: "fit-content",
                whiteSpace: "nowrap",
                pl: "4px",
                ml: "14px",
              }}
              onClick={() => {
                window.open("mailto:kancelar@ak-hpk.cz", "_self");
              }}
            >
              <EmailRoundedIcon
                sx={{
                  color: "white",
                  height: "17px",
                  width: "17px",
                  mr: "4px",
                  mb: "1px",
                }}
              />
              <Typography
                sx={{
                  color: "white",
                  fontSize: "13px",
                  textDecoration: "none",
                  fontWeight: "normal",
                }}
              >
                kancelar@ak-hpk.cz
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BaseButtonPublic from "../Main/BaseButtonPublic";

import LinkContainer from "../Main/LinkContainer";
import MobileMenuIcon from "./MobileMenuIcon";
import { appMain, smallTitleStyle } from "../../Design/theme";
import { PhoneEnabledRounded } from "@mui/icons-material";
import { Motion } from "../Main/Motion";
/*import { baseRed } from "../../Design/theme";
import MobileMenuIcon from "../Base/MobileMenuIcon";
import LinkContainer from "../Base/LinkContainer";*/

export default function MobileDrawerMenu({
  state,
  setState,
  references,
  elevated,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    {
      name: "Naše služby",
      path: "/sluzby",
    },
    {
      name: "Kontakt",
      path: "/kontakt",
    },
    {
      name: "Ochrana soukromí",
      path: "/ochrana",
    },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      sx={{ width: "auto", bgcolor: "#fbfbfb" }}
      role="presentation"
      //onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((data, index) => (
          <Box
            key={data.name ? data.name : "Empty"}
            onClick={() => {
              if (data.path === location.pathname) return;
              setState(false);
              navigate(data.path);
            }}
          >
            <ListItem
              sx={{
                mt: index == 0 ? "10px" : "0px",
                "&:hover": {
                  bgcolor: "rgba(200, 200, 200, .1)",
                },
              }}
              disablePadding
            >
              <ListItemButton sx={{ mt: index == 0 ? "46px" : "0px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: "10px",
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="navHeader"
                        sx={{ mb: "10px", ...smallTitleStyle }}
                      >
                        {data.name}
                      </Typography>
                    }
                  />
                </Box>
              </ListItemButton>
            </ListItem>
            {index !== navItems.length - 1 && (
              <ListItem key={"Divider" + index} disablePadding>
                <Box
                  sx={{ width: "100%", height: "1px", ml: "18px", my: "0px" }}
                >
                  <Divider />
                </Box>
              </ListItem>
            )}
          </Box>
        ))}
        <ListItem key={"SignoutListItem"} disablePadding>
          <Box
            key={"MenuItemProfileButton"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "80px",
              mt: "20px",
              mb: "10px",
            }}
          >
            <LinkContainer>
              <Motion scale={1.05}>
                <Box
                  sx={{
                    my: "auto",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    width: "fit-content",
                    px: "8px",
                  }}
                  onClick={() => {
                    window.open("mailto:kancelar@ak-hpk.cz", "_self");
                  }}
                >
                  {/*<PhoneEnabledRounded
                    sx={{
                      color: appMain,
                      height: "24px",
                      width: "24px",
                      mr: "6px",
                      mb: "2px",
                      ml: "-2px",
                    }}
                  />*/}
                  <Typography
                    sx={{
                      color: appMain,
                      fontSize: "20px",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    kancelar@ak-hpk.cz
                  </Typography>
                </Box>
              </Motion>
            </LinkContainer>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"TopDrawerMenu"}>
        {/*<IconButton
          icon={state ? <MenuOpenIcon /> : <MenuIcon />}
          iconProps={{ fill: `#fbfbfb` }}
          sxProps={{
            width: "42px",
            height: "42px",
          }}
          onClick={() => {
            setState((prev) => !prev);
          }}
        />*/}
        <MobileMenuIcon
          open={state}
          onClick={() => {
            setState((prev) => !prev);
          }}
          elevated={elevated}
        />
        <SwipeableDrawer
          anchor={"top"}
          open={state}
          disableScrollLock={true}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          BackdropProps={{ invisible: true }}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            zIndex: 9999,
          }}
          disableSwipeToOpen
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

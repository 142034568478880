import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { NOT_SELECTABLE } from "../../Design/theme";

export default function LinkContainer({ url, children }) {
  if (!url) {
    return children;
  } else {
    return (
      <Box
        sx={{
          textDecoration: "none",
          width: "fit-content",
          height: "fit-content",
          ...NOT_SELECTABLE,
        }}
        component={Link}
        target="_blank"
        to={url}
        href={url}
      >
        {children}
      </Box>
    );
  }
}

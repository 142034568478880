import React, { lazy } from "react";
import Loadable from "./Loaders/Loadable";

import { services } from "../Components/Pages/Services";

const MainPage = Loadable(lazy(() => import("../Components/Pages/MainPage")));

const OsobniUdaje = Loadable(
  lazy(() => import("../Components/Pages/Legal/OsobniUdaje"))
);
const Kontakt = Loadable(lazy(() => import("../Components/Pages/Kontakt")));
const Services = Loadable(lazy(() => import("../Components/Pages/Services")));

let baseRoutes = [
  {
    path: "/ochrana",
    element: <OsobniUdaje />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
  },
  {
    path: "/sluzby",
    element: <Services />,
  },
  {
    path: "/pravo",
    element: <Services />,
  },
  {
    path: "/",
    element: <MainPage />,
  },
  ...services.map((data) => {
    return { path: data.path, element: data.element };
  }),
];

const routes = [
  ...baseRoutes,
  {
    path: "*",
    element: <MainPage />,
  },
];

export default routes;

import "./App.css";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import routes from "./Resources/Router/router";
import themeFile from "./Resources/Design/theme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Navbar from "./Resources/Components/Navbars/Navbar";
import Footer from "./Resources/Components/Navbars/Footer";
import Loadable from "./Resources/Router/Loaders/Loadable";
import { lazy, useEffect } from "react";

const ErrorPage = Loadable(
  lazy(() => import("./Resources/Components/Pages/ErrorPage"))
);

export const theme = createTheme(themeFile());

function ErrorBoundary() {
  let error = useRouteError();
  console.error("Error loading page ", error);

  return (
    <div style={{ minWidth: "310px" }}>
      <Navbar />
      <div
        style={{
          paddingTop: "62px",
          paddingBottom: "42px",
          minHeight: "100vh",
        }}
      >
        <ErrorPage />
      </div>
      <Footer />
    </div>
  );
}

const PageComponent = ({ route }) => {
  useEffect(() => {
    if (window) window.scrollTo(0, 0);
  });

  return (
    <div style={{ minWidth: "310px" }}>
      <Navbar />
      <div
        style={{
          paddingTop: "62px",
          paddingBottom: "42px",
          minHeight: "100vh",
        }}
      >
        {route.element}
      </div>
      <Footer />
    </div>
  );
};

const getRoutes = () => {
  let finalRoutes = [];
  routes.map((route) => {
    finalRoutes.push({
      path: route.path,
      element: <PageComponent route={route} />,
      errorElement: <ErrorBoundary />,
    });
  });

  return finalRoutes;
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Outlet />,
      children: getRoutes(),
    },
  ]);

  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
